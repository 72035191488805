import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';
import { useToast } from '../ui/use-toast';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import { Badge } from '../ui/badge';
import { Label } from '../ui/label';
import { boardApi } from '../../services/api';
import { cn } from '../../lib/utils';

const MaturityProgress = ({ boardId, accountId }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showPathDialog, setShowPathDialog] = useState(false);
  const [selectedPath, setSelectedPath] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { data: status, isLoading } = useQuery(
    ['board-maturity', boardId],
    () => boardApi.checkMaturity(boardId),
    {
      refetchInterval: 20000
    }
  );

  const handleLevelUp = async () => {
    try {
      if (status?.nextLevelOptions?.length > 0) {
        setSelectedPath(null);
        setShowPathDialog(true);
        return;
      }

      const result = await boardApi.levelUp(boardId);
      navigate(`/accounts/${accountId}/board/workload/${result._id}`);
      toast({
        title: "Workload Leveled Up",
        description: "Successfully upgraded workload maturity level"
      });
    } catch (error) {
      console.error('Error in level up process:', error);
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to level up board",
        variant: "destructive"
      });
    }
  };

  const handlePathSelection = async () => {
    if (!selectedPath) return;

    try {
      const result = await boardApi.levelUp(boardId, { 
        selectedPath: {
          template: selectedPath.template,
          name: selectedPath.name,
          description: selectedPath.description,
          recommendedFor: selectedPath.recommendedFor || []
        }
      });

      setShowPathDialog(false);
      navigate(`/accounts/${accountId}/board/workload/${result._id}`);
      toast({
        title: "Workload Leveled Up",
        description: "Successfully upgraded workload maturity level"
      });
    } catch (error) {
      console.error('Error leveling up with path:', error);
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to level up board",
        variant: "destructive"
      });
    }
  };

  if (!status?.currentLevel) return null;

  const getProgressColor = (percentage) => {
    if (percentage < 33) return 'bg-red-500';
    if (percentage < 66) return 'bg-yellow-500';
    return 'bg-green-500';
  };

  return (
    <>
      <div className="space-y-4 mb-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <h3 className="text-sm font-medium text-gray-900">Workload Progress</h3>
          </div>
          {status.canLevelUp && (
            <Button onClick={handleLevelUp}>Level Up Workload</Button>
          )}
        </div>

        <div className="space-y-2">
          <div className="relative w-full h-2 bg-gray-100 rounded-full">
            <div
              className={cn(
                "h-full rounded-full transition-all duration-300",
                getProgressColor(status.completionPercentage)
              )}
              style={{ width: `${status.completionPercentage || 0}%` }}
            />
          </div>
          <p className="text-sm text-gray-500">
            {status.canLevelUp ? "Ready to level up!" : status.reason}
          </p>
        </div>
      </div>

      {/* Keep the existing dialog code unchanged */}
      <Dialog open={showPathDialog} onOpenChange={setShowPathDialog}>
        <DialogContent className="bg-white">
          <DialogHeader>
            <DialogTitle>Select Maturity Path</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <RadioGroup
              value={selectedPath?.template}
              onValueChange={(value) => {
                const path = status.nextLevelOptions?.find(opt => opt.template === value);
                if (path) {
                  setSelectedPath({
                    template: path.template,
                    name: path.name,
                    description: path.description,
                    recommendedFor: path.recommendedFor || []
                  });
                }
              }}
            >
              {status?.nextLevelOptions?.map((option) => (
                <div key={option.template} className="flex items-start space-x-3 space-y-3">
                  <RadioGroupItem 
                    value={option.template}
                    id={option.template}
                    className="mt-1"
                  />
                  <div className="grid gap-1.5">
                    <Label htmlFor={option.template} className="text-base font-medium">
                      {option.name}
                    </Label>
                    <p className="text-sm text-gray-500">
                      {option.description}
                    </p>
                    {option.recommendedFor?.length > 0 && (
                      <div className="flex flex-wrap gap-1 mt-1">
                        {option.recommendedFor.map((rec, i) => (
                          <Badge key={i} variant="secondary" className="text-xs">
                            {rec}
                          </Badge>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </RadioGroup>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setShowPathDialog(false);
                setSelectedPath(null);
              }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handlePathSelection}
              disabled={!selectedPath}
            >
              Proceed with Level Up
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MaturityProgress;