import React, { useRef, useEffect, useState } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import BoardMaturityBadge from '../components/Board/BoardMaturityBadge';
import { boardApi } from '../services/api';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Board from '../components/Board/Board';
import { cn } from '../lib/utils';

const BoardPage = () => {
  const { accountId } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const activeBoard = searchParams.get('board');
  const boardRefs = useRef({});
  const [expandedBoards, setExpandedBoards] = useState(new Set());

  const pathParts = location.pathname.split('/');
  const boardType = pathParts[pathParts.indexOf('board') + 1] || 
                   (pathParts.includes('workload') ? 'workload' : 'all');

  const getBoardType = (urlType) => {
    const typeMap = {
      'all': 'All',
      'business': 'Business',
      'enablement': 'Enablement',
      'workload': 'Workload'
    };
    return typeMap[urlType] || urlType;
  };

  const { data: boards, isLoading, error } = useQuery(
    ['boards', accountId, boardType],
    async () => {
      const params = { 
        accountId, 
        ...(boardType !== 'all' && { type: getBoardType(boardType) })
      };
      return await boardApi.getAllBoards(params);
    },
    {
      enabled: !!accountId
    }
  );

  // Update expanded boards when activeBoard changes or boards load
  useEffect(() => {
    if (activeBoard && boards) {
      setExpandedBoards(new Set([activeBoard]));

      // Wait for next render cycle to ensure ref is available
      setTimeout(() => {
        if (boardRefs.current[activeBoard]) {
          boardRefs.current[activeBoard].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          // Add some padding from the top of the viewport
          window.scrollBy(0, -100);
        }
      }, 100);
    }
  }, [activeBoard, boards]);

  const toggleBoard = (boardId) => {
    setExpandedBoards((prev) => {
      const next = new Set(prev);
      if (next.has(boardId)) {
        next.delete(boardId);
      } else {
        next.clear(); // Collapse all other boards
        next.add(boardId);
      }
      return next;
    });
  };

  const getBoardTitle = () => {
    switch (boardType) {
      case 'all':
        return 'All Tasks';
      case 'workload':
        return 'Workloads';
      case 'business':
        return 'Business Tasks';
      case 'enablement':
        return 'Enablement Tasks';
      default:
        return 'Board';
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-lg">Loading boards...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 p-4">
        Error loading boards: {error.message}
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">{getBoardTitle()}</h1>
        <Button
          variant="outline"
          onClick={() => {
            if (expandedBoards.size === boards.length) {
              setExpandedBoards(new Set());
            } else {
              setExpandedBoards(new Set(boards.map(b => b._id)));
            }
          }}
        >
          {expandedBoards.size === boards.length ? 'Collapse All' : 'Expand All'}
        </Button>
      </div>
  
      <div className="grid gap-2">
        {boards?.map(board => (
          <Card
            key={board._id}
            ref={el => (boardRefs.current[board._id] = el)}
            className={cn(
              "shadow-sm transition-all duration-300",
              !expandedBoards.has(board._id) && "hover:bg-gray-50"
            )}
          >
            <CardHeader 
              className={cn(
                "cursor-pointer",
                !expandedBoards.has(board._id) && "pb-3 pt-3"
              )}
              onClick={() => toggleBoard(board._id)}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <CardTitle>{board.name}</CardTitle>
                  <BoardMaturityBadge board={board} />
                </div>
                <Button variant="ghost" size="sm">
                  {expandedBoards.has(board._id) ? (
                    <ChevronUp className="h-4 w-4" />
                  ) : (
                    <ChevronDown className="h-4 w-4" />
                  )}
                </Button>
              </div>
            </CardHeader>
            {expandedBoards.has(board._id) && (
              <CardContent>
                <Board boardId={board._id} />
              </CardContent>
            )}
          </Card>
        ))}
      </div>
  
      {(!boards || boards.length === 0) && (
        <div className="text-center py-10">
          <p className="text-gray-500">No boards found for this category.</p>
        </div>
      )}
    </div>
  );
};

export default BoardPage;