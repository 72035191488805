import React, { useState } from 'react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { accountApi, boardApi, templateApi } from '../services/api';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Plus, Users, Briefcase, Lightbulb, Layout } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '../components/ui/dialog';
import { Input } from '../components/ui/input';
import { Badge } from '../components/ui/badge';
import { Alert, AlertDescription } from '../components/ui/alert';
import { useToast } from '../components/ui/use-toast';
import BoardPage from './BoardPage';
import BoardCard from '../components/Board/BoardCard'
import { cn } from '../lib/utils';

// Color tokens
const colors = {
  brand: {
    primary: '#1f513f',
    secondary: '#406eb5',
  },
  text: {
    primary: 'rgba(12, 11, 8, 0.75)',
    secondary: 'rgba(66, 59, 47, 0.75)',
    emphasis: '#20201d',
  },
  background: {
    primary: '#ffffff',
    secondary: '#f4f2ed',
    tertiary: '#f9f8f6',
  },
  border: {
    primary: '#dcd6cb',
    secondary: '#928b80',
  }
};

// Template Selection Dialog Component
const TemplateSelection = ({ 
  open, 
  onOpenChange, 
  boardType, 
  accountId,
  onBoardCreated 
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [boardName, setBoardName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);

  // Reset state when dialog opens
  React.useEffect(() => {
    if (open) {
      setSelectedTemplate(null);
      setBoardName('');
      setError(null);
    }
  }, [open]);

  // Fetch templates filtered by type
  const { data: templates, isLoading } = useQuery(
    ['templates', boardType],
    async () => {
      try {
        const result = await templateApi.getAllTemplates({ 
          type: boardType,
          isActive: true 
        });
        return result;
      } catch (err) {
        console.error('Frontend: Template fetch error:', err);
        setError('Failed to load templates. Please try again.');
        throw err;
      }
    },
    {
      enabled: open && Boolean(boardType)
    }
  );

  const handleCreateBoard = async () => {
    if (!selectedTemplate || !boardName.trim()) return;
  
    setIsCreating(true);
    setError(null);

    try {
      const board = await templateApi.createBoardFromTemplate(selectedTemplate._id, {
        name: boardName.trim(),
        accountId: accountId,
        type: boardType
      });
  
      // Close dialog before navigation
      onOpenChange(false);
      
      // Navigate to new board after dialog is closed
      onBoardCreated?.(board);
    } catch (error) {
      console.error('Error creating board:', error);
      setError(error.response?.data?.message || 'Failed to create board. Please try again.');
      setIsCreating(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] bg-white max-h-[90vh] flex flex-col">
        <DialogHeader>
          <DialogTitle className={`text-[${colors.text.emphasis}]`}>
            Create New {boardType} Board
          </DialogTitle>
          <DialogDescription className={`text-[${colors.text.secondary}]`}>
            Select a template to create your new board from
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4 py-4 flex-1 overflow-y-auto">
          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <Input
            placeholder="Board name"
            value={boardName}
            onChange={(e) => setBoardName(e.target.value)}
            className="mb-4"
          />

          {isLoading ? (
            <div className={`text-center py-8 text-[${colors.text.secondary}]`}>
              Loading templates...
            </div>
          ) : templates?.length === 0 ? (
            <div className="text-center py-8">
              <Layout className={`w-12 h-12 mx-auto text-[${colors.text.secondary}] mb-4`} />
              <p className={`text-[${colors.text.secondary}]`}>
                No templates available for {boardType} boards
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4">
              {templates?.map((template) => (
                <Card 
                  key={template._id}
                  className={cn(
                    'cursor-pointer transition-all',
                    selectedTemplate?._id === template._id 
                      ? 'ring-2 ring-[#1f513f]' 
                      : `hover:bg-[${colors.background.tertiary}]`
                  )}
                  onClick={() => setSelectedTemplate(template)}
                >
                  <CardHeader>
                    <CardTitle className="flex items-center justify-between">
                      <span>{template.name}</span>
                      <Layout className="w-4 h-4 text-gray-500" />
                    </CardTitle>
                    <p className="text-sm text-gray-500">
                      {template.description || 'No description provided'}
                    </p>
                  </CardHeader>
                  <CardContent>
                    <div className="flex items-center space-x-2">
                      <Badge variant="secondary">
                        {template.columns?.length || 0} columns
                      </Badge>
                      <Badge variant="secondary">
                        {template.defaultTasks?.length || 0} default tasks
                      </Badge>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
        </div>

        <DialogFooter className="mt-6">
          <Button 
            variant="outline" 
            onClick={() => onOpenChange(false)}
            disabled={isCreating}
            className={`border-[${colors.border.primary}]`}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleCreateBoard}
            disabled={!selectedTemplate || !boardName.trim() || isCreating}
            className={`bg-[${colors.brand.primary}] text-white hover:bg-[${colors.background.secondary}] hover:text-[${colors.brand.primary}]`}
          >
            {isCreating ? 'Creating...' : 'Create Board'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
// eslint-disable-next-line no-unused-vars
const StatsCard = ({ title, value }) => (
  <Card className={cn(
    `bg-[${colors.background.tertiary}]`,
    `hover:bg-[${colors.background.secondary}]`,
    'transition-colors'
  )}>
    <CardContent className="pt-6">
      <div className={`text-2xl font-bold text-[${colors.brand.primary}]`}>
        {value}
      </div>
      <div className={`text-sm text-[${colors.text.secondary}]`}>
        {title}
      </div>
    </CardContent>
  </Card>
);

// Empty Board Card Component
const EmptyBoardCard = ({ type, Icon, onCreateBoard }) => (
  <Card className={cn(
    'border-dashed',
    `border-[${colors.border.primary}]`
  )}>
    <CardContent className="flex flex-col items-center justify-center py-8">
      <Icon className={cn(
        'w-8 h-8 mb-2',
        `text-[${colors.text.secondary}]`
      )} />
      <p className={`text-[${colors.text.secondary}] text-center`}>
        No {type} boards yet
      </p>
      <Button 
        variant="ghost" 
        size="sm" 
        className={cn(
          'mt-2',
          `text-[${colors.brand.primary}]`,
          `hover:bg-[${colors.background.secondary}]`
        )}
        onClick={onCreateBoard}
      >
        Create your first {type} board
      </Button>
    </CardContent>
  </Card>
);

// Separate Dashboard Component
const AccountDashboard = () => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showTemplateDialog, setShowTemplateDialog] = useState(false);
  const [selectedBoardType, setSelectedBoardType] = useState(null);
  const { toast } = useToast();

  // Account query
  // eslint-disable-next-line no-unused-vars
  const { 
    data: account, 
    // eslint-disable-next-line no-unused-vars
    isLoading: accountLoading, 
    // eslint-disable-next-line no-unused-vars
    error: accountError 
  } = useQuery(
    ['account', accountId],
    () => accountApi.getAccountById(accountId),
    {
      enabled: Boolean(accountId),
      retry: 1,
      onError: (error) => {
        console.error('Account fetch error:', error);
        if (error?.response?.status === 400 || error?.response?.status === 404) {
          navigate('/');
        }
      }
    }
  );

  // Boards query
  const { 
    data: boards, 
    // eslint-disable-next-line no-unused-vars
    isLoading: boardsLoading,
    // eslint-disable-next-line no-unused-vars
    error: boardsError 
  } = useQuery(
    ['boards', accountId],
    () => boardApi.getAllBoards({ accountId }),
    {
      enabled: Boolean(accountId)
    }
  );

  const boardTypes = [
    { 
      type: 'Workload', 
      icon: Users, 
      description: 'Track team member assignments and capacity',
      path: 'workload'
    },
    { 
      type: 'Business', 
      icon: Briefcase, 
      description: 'Manage business initiatives and goals',
      path: 'business'
    },
    { 
      type: 'Enablement', 
      icon: Lightbulb, 
      description: 'Organize enablement and training activities',
      path: 'enablement'
    }
  ];

  // Group boards by type
  const boardsByType = React.useMemo(() => {
    if (!boards) return {};
    
    return boards.reduce((acc, board) => {
      const type = board.type || 'Other';
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(board);
      return acc;
    }, {});
  }, [boards]);

  const handleCreateBoard = (type) => {
    setSelectedBoardType(type);
    setShowTemplateDialog(true);
  };

  const handleBoardCreated = (board) => {
    queryClient.invalidateQueries(['boards', accountId]);

    // Calculate the correct navigation path
    const pathType = board.type.toLowerCase();
    
    const navigationPath = pathType === 'workload' 
      ? `/accounts/${accountId}/workload/${board._id}`
      : `/accounts/${accountId}/board/${pathType}`;
    
    navigate(navigationPath);
  };

  // Template Selection Dialog
  <TemplateSelection
    open={showTemplateDialog}
    onOpenChange={setShowTemplateDialog}
    boardType={selectedBoardType}
    accountId={accountId}
    onBoardCreated={handleBoardCreated}
  />;

  const handleBoardClick = (board) => {
    const pathType = board.type.toLowerCase();
    const baseUrl = `/accounts/${accountId}/`;
    
    const navigationPath = pathType === 'workload'
      ? `${baseUrl}workload/default`
      : `${baseUrl}board/${pathType}`;
  
    // Add the board ID as a query parameter
    navigate(`${navigationPath}?board=${board._id}`);
  };

  return (
    <div className="space-y-8">
      {/* Enhanced Account Header with brand color background */}
      <div className={cn(
        `bg-[${colors.brand.primary}]`,
        'px-6 py-8 -mx-6 -mt-6'
      )}>
        <div className="max-w-7xl mx-auto">
          <div className="space-y-1">
            <h1 className="text-3xl font-bold text-white">
              {account?.name || 'Loading...'}
            </h1>
            <p className="text-white/80">
              {account?.parentAccount ? 'Sub-account' : 'Main Account'}
            </p>
          </div>
  
          {/* Quick Stats */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
            <Card className="bg-white/95 backdrop-blur border-none shadow-lg">
              <CardContent className="pt-6">
                <div className="flex items-center space-x-2">
                  <div className={cn(
                    'p-2 rounded-full',
                    `bg-[${colors.brand.primary}]/10`
                  )}>
                    <Layout className={`w-5 h-5 text-[${colors.brand.primary}]`} />
                  </div>
                  <div>
                    <div className="text-2xl font-bold text-[#20201d]">
                      {boards?.length || 0}
                    </div>
                    <div className="text-sm text-[rgba(66,59,47,0.75)]">
                      Total Boards
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
  
            <Card className="bg-white/95 backdrop-blur border-none shadow-lg">
              <CardContent className="pt-6">
                <div className="flex items-center space-x-2">
                  <div className={cn(
                    'p-2 rounded-full',
                    `bg-[${colors.brand.primary}]/10`
                  )}>
                    <Users className={`w-5 h-5 text-[${colors.brand.primary}]`} />
                  </div>
                  <div>
                    <div className="text-2xl font-bold text-[#20201d]">
                      {boardsByType?.['Workload']?.length || 0}
                    </div>
                    <div className="text-sm text-[rgba(66,59,47,0.75)]">
                      Active Workloads
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
  
            <Card 
            className="bg-white/95 backdrop-blur border-none shadow-lg">
              <CardContent className="pt-6">
                <div className="flex items-center space-x-2">
                  <div className={cn(
                    'p-2 rounded-full',
                    `bg-[${colors.brand.primary}]/10`
                  )}>
                    <Briefcase className={`w-5 h-5 text-[${colors.brand.primary}]`} />
                  </div>
                  <div>
                    <div className="text-2xl font-bold text-[#20201d]">
                      {boardsByType?.['Business']?.length || 0}
                    </div>
                    <div className="text-sm text-[rgba(66,59,47,0.75)]">
                      Business Boards
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
  
      <div className="px-6">
        {/* Template Selection Dialog */}
        <TemplateSelection
          open={showTemplateDialog}
          onOpenChange={setShowTemplateDialog}
          boardType={selectedBoardType}
          accountId={accountId}
          onBoardCreated={handleBoardCreated}
        />
  
        {/* Board Sections */}
        <div className="space-y-6">
          {boardTypes.map(({ type, icon: Icon, description, path }) => {
            const boardsOfType = boardsByType[type] || [];
            
            return (
              <div key={type} className="space-y-4">
                <div className="flex items-center justify-between">
                  <div>
                    <h2 className={`text-xl font-semibold flex items-center text-[${colors.text.emphasis}]`}>
                      <Icon className="w-5 h-5 mr-2" />
                      {type} Boards
                    </h2>
                    <p className={`text-sm text-[${colors.text.secondary}]`}>{description}</p>
                  </div>
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={() => handleCreateBoard(type)}
                    className={cn(
                      `bg-[${colors.brand.primary}]`,
                      'text-white',
                      `hover:bg-[${colors.background.secondary}]`,
                      `hover:text-[${colors.brand.primary}]`,
                      `border-[${colors.brand.primary}]`
                    )}
                  >
                    <Plus className="w-4 h-4 mr-2" />
                    New {type} Board
                  </Button>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {boardsOfType.map(board => (
  <BoardCard
    key={board._id}
    board={board}
    onClick={() => handleBoardClick(board)}
    onDelete={async (boardId) => {
      try {
        await boardApi.deleteBoard(boardId);
        queryClient.invalidateQueries(['boards', accountId]);
        toast({
          title: "Board deleted",
          description: "The board has been successfully deleted."
        });
      } catch (error) {
        console.error('Error deleting board:', error);
        toast({
          title: "Error",
          description: "Failed to delete board. Please try again.",
          variant: "destructive"
        });
      }
    }}
  />
))}
                  
                  {boardsOfType.length === 0 && (
                    <EmptyBoardCard
                      type={type}
                      Icon={Icon}
                      onCreateBoard={() => handleCreateBoard(type)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// Main AccountPage Component with Routes
const AccountPage = () => {
  return (
    <Routes>
      <Route index element={<AccountDashboard />} />
      <Route path="board/:boardType/:boardId" element={<BoardPage />} />
      <Route path="workload/:workloadId" element={<BoardPage />} />
      <Route path="settings" element={<div>Settings Page</div>} />
      <Route path="help" element={<div>Help Page</div>} />
    </Routes>
  );
};

export default AccountPage;