// src/components/UserProfile.js
import React from 'react';
import { useAuth } from "react-oidc-context";
import { Card, CardHeader, CardContent, CardTitle } from "./ui/card";
import { Avatar, AvatarFallback } from "./ui/avatar";

const UserProfile = () => {
  const auth = useAuth();
  const profile = auth.user?.profile;


  if (!profile) {
    return <div>Loading profile...</div>;
  }

  return (
    <div className="container mx-auto p-6">
      <Card>
        <CardHeader>
          <div className="flex items-center space-x-4">
            <Avatar>
              <AvatarFallback>
                {profile.given_name?.[0]}{profile.family_name?.[0]}
              </AvatarFallback>
            </Avatar>
            <CardTitle>User Profile</CardTitle>
          </div>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid gap-4">
            <div>
              <h3 className="font-semibold">Email</h3>
              <p className="text-gray-600">{profile.email}</p>
            </div>
            <div>
              <h3 className="font-semibold">Name</h3>
              <p className="text-gray-600">{profile.name}</p>
            </div>
            {profile.groups && (
              <div>
                <h3 className="font-semibold">Groups</h3>
                <ul className="list-disc list-inside text-gray-600">
                  {profile.groups.map((group) => (
                    <li key={group}>{group}</li>
                  ))}
                </ul>
              </div>
            )}
            <div>
              <h3 className="font-semibold">Token Details</h3>
              <p className="text-sm text-gray-500">Expires: {new Date(auth.user?.expires_at * 1000).toLocaleString()}</p>
                <div>
                <pre> Hello: {auth.user?.profile.email} </pre>
                <pre> ID Token: {auth.user?.id_token} </pre>
                <pre> Access Token: {auth.user?.access_token} </pre>
                <pre> Refresh Token: {auth.user?.refresh_token} </pre>
                <pre> session_state: {auth.user?.session_state} </pre>
                <pre> name: {auth.user?.profile.name?.toString} </pre>
                <pre> profile: {auth.user?.profile.sub} </pre>
                <pre> groups: </pre>
                {auth.user?.profile["cognito:groups"].map((group) => (
                <pre> {group} </pre>
              ))}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserProfile;