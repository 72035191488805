import React from 'react';
import { Star, Stars } from 'lucide-react';
import { useQuery } from 'react-query';
import { templateApi } from '../../services/api';
import { Badge } from '../../components/ui/badge';
import { cn } from '../../lib/utils';

const BoardMaturityBadge = ({ board }) => {
  // Get template details if board was created from template
  const { data: template } = useQuery(
    ['template', board?.template],
    () => board?.template ? templateApi.getTemplateById(board.template) : null,
    {
      enabled: !!board?.template
    }
  );

  // If board has no template or template is not a workload template
  if (!template?.maturityPath?.isWorkloadTemplate) {
    return (
      <Badge 
        variant="outline" 
        className="bg-gray-100 text-gray-600 hover:bg-gray-200 transition-colors"
      >
        <Star className="w-3 h-3 mr-1" />
        <span className="text-xs">Standard</span>
      </Badge>
    );
  }

  // Color variations based on level
  const levelColors = {
    1: 'bg-blue-100 text-blue-800 hover:bg-blue-200',
    2: 'bg-green-100 text-green-800 hover:bg-green-200',
    3: 'bg-yellow-100 text-yellow-800 hover:bg-yellow-200',
    4: 'bg-purple-100 text-purple-800 hover:bg-purple-200',
    5: 'bg-pink-100 text-pink-800 hover:bg-pink-200'
  };

  return (
    <Badge 
      variant="outline" 
      className={cn(
        levelColors[template.maturityPath.level] || 'bg-gray-100 text-gray-600',
        'transition-colors'
      )}
    >
      <Stars className="w-3 h-3 mr-1" />
      <span className="text-xs">Level {template.maturityPath.level}</span>
    </Badge>
  );
};

export default BoardMaturityBadge;