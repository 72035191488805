import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Plus, FileCode } from 'lucide-react';
import { Button } from '../components/ui/button';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { templateApi } from '../services/api';

const TemplatesPage = () => {
  const navigate = useNavigate();
  const { data: templates, isLoading } = useQuery('templates', () => 
    templateApi.getAllTemplates()
  );

  const handleTemplateClick = (templateId) => {
    navigate(`/templates/edit/${templateId}`);
  };

  return (
    <div className="space-y-6 p-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Board Templates</h1>
          <p className="text-gray-500 mt-1">Manage and create board templates for your organization</p>
        </div>
        <Button onClick={() => navigate('/templates/new')}>
          <Plus className="w-4 h-4 mr-2" />
          Create Template
        </Button>
      </div>

      {isLoading ? (
        <div className="text-center py-10">Loading templates...</div>
      ) : templates?.length === 0 ? (
        <div className="text-center py-10">
          <FileCode className="w-12 h-12 mx-auto text-gray-400 mb-4" />
          <p className="text-gray-500">No templates created yet.</p>
          <Button 
            className="mt-4"
            onClick={() => navigate('/templates/new')}
          >
            Create your first template
          </Button>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {templates?.map(template => (
            <Card 
              key={template._id} 
              className="hover:shadow-md transition-shadow cursor-pointer"
              onClick={() => handleTemplateClick(template._id)}
            >
              <CardHeader>
                <CardTitle>{template.name}</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-sm text-gray-500">
                  {template.type} Template
                </p>
                {template.description && (
                  <p className="text-sm text-gray-600 mt-2">
                    {template.description}
                  </p>
                )}
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default TemplatesPage;