import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Search, Plus } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from './ui/dialog';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Badge } from './ui/badge';
import { ScrollArea } from './ui/scroll-area';
import { taskApi, boardApi } from '../services/api';
import '../styles/Buttons.css';

const TaskSelector = ({ boardId, onTasksSelected, exclude = [] }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedTasks, setSelectedTasks] = useState([]);

  // Fetch all tasks
  const { data: tasks, isLoading } = useQuery(
    ['all-tasks'],
    () => taskApi.getAllTasks(),
    {
      enabled: open
    }
  );

  // Filter tasks
  const filteredTasks = tasks?.filter(task => 
    // Exclude tasks that are already on this board
    !exclude.includes(task._id) &&
    // Filter by search term
    (task.title.toLowerCase().includes(search.toLowerCase()) ||
     task.description?.toLowerCase().includes(search.toLowerCase()))
  ) || [];

  const handleTaskToggle = (task) => {
    setSelectedTasks(prev => 
      prev.includes(task._id) 
        ? prev.filter(id => id !== task._id)
        : [...prev, task._id]
    );
  };

  const handleSubmit = async () => {
    try {
      // Add all selected tasks to the board
      await Promise.all(
        selectedTasks.map(taskId =>
          boardApi.addTaskToBoard(boardId, taskId)
        )
      );
      
      onTasksSelected?.();
      setOpen(false);
      setSelectedTasks([]);
    } catch (error) {
      console.error('Error adding tasks to board:', error);
    }
  };

  return (
    <>
      <Button
        size="sm"
        onClick={() => setOpen(true)}
        className="button button-secondary flex items-center"
      >
        <Plus className="w-4 h-4 mr-2" />
        Import Tasks
      </Button>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="max-w-2xl bg-white">
          <DialogHeader>
            <DialogTitle>Add Existing Tasks</DialogTitle>
          </DialogHeader>

          <div className="space-y-4">
            <Input
              placeholder="Search tasks..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              prefix={<Search className="w-4 h-4 text-gray-400" />}
            />

            <ScrollArea className="h-[400px] border rounded-lg p-4">
              {isLoading ? (
                <div className="text-center py-4 text-gray-500">Loading tasks...</div>
              ) : filteredTasks.length === 0 ? (
                <div className="text-center py-4 text-gray-500">No tasks found</div>
              ) : (
                <div className="space-y-2">
                  {filteredTasks.map(task => (
                    <div
                      key={task._id}
                      className={`p-3 border rounded-lg cursor-pointer transition-colors ${
                        selectedTasks.includes(task._id)
                          ? 'bg-blue-50 border-blue-200'
                          : 'hover:bg-gray-50'
                      }`}
                      onClick={() => handleTaskToggle(task)}
                    >
                      <div className="flex items-start justify-between">
                        <div>
                          <h3 className="font-medium">{task.title}</h3>
                          {task.description && (
                            <p className="text-sm text-gray-500 mt-1">
                              {task.description}
                            </p>
                          )}
                        </div>
                        <Badge>{task.type}</Badge>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ScrollArea>
          </div>

          <DialogFooter>
            <Button variant="outline" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={selectedTasks.length === 0}
            >
              Add {selectedTasks.length} Task{selectedTasks.length !== 1 ? 's' : ''}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TaskSelector;