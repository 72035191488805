// src/pages/UserPages.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserProfile from '../components/user/UserProfile';
import UserSettings from '../components/user/UserSettings';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useToast } from '../components/ui/use-toast';

const UserPages = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  // Get current user data - you'll need to implement this API endpoint
  const { data: user, isLoading } = useQuery(
    'currentUser',
    () => fetch('/api/users/me').then(res => res.json())
  );

  // Update user profile mutation
  const updateProfileMutation = useMutation(
    (updateData) => fetch('/api/users/me', {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updateData)
    }).then(res => res.json()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('currentUser');
        toast({
          title: "Success",
          description: "Profile updated successfully",
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: "Failed to update profile",
          variant: "destructive",
        });
      }
    }
  );

  // Update user settings mutation
  const updateSettingsMutation = useMutation(
    (settings) => fetch('/api/users/me/settings', {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(settings)
    }).then(res => res.json()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('currentUser');
        toast({
          title: "Success",
          description: "Settings updated successfully",
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: "Failed to update settings",
          variant: "destructive",
        });
      }
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-6 max-w-4xl">
      <Routes>
        <Route 
          path="profile" 
          element={
            <UserProfile 
              user={user} 
              onUpdateProfile={updateProfileMutation.mutate}
            />
          } 
        />
        <Route 
          path="settings" 
          element={
            <UserSettings 
              user={user} 
              onUpdateSettings={updateSettingsMutation.mutate}
            />
          } 
        />
      </Routes>
    </div>
  );
};

export default UserPages;