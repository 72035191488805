import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { accountApi } from '../services/api';
import { Button } from '../components/ui/button';
import CreateAccountDialog from '../components/CreateAccountDialog';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { Building2, Users } from 'lucide-react';
import { Badge } from '../components/ui/badge';

const Home = () => {
  const { data: accounts, isLoading, error } = useQuery('accounts', () => 
    accountApi.getAccounts(), {
      onSuccess: (data) => console.log('Accounts loaded:', data),
      onError: (err) => console.error('Error loading accounts:', err)
    }
  );

  if (isLoading) return (
    <div className="flex items-center justify-center min-h-[50vh]">
      <div className="text-lg">Loading accounts...</div>
    </div>
  );

  if (error) return (
    <div className="text-red-500">
      Error loading accounts: {error.message}
    </div>
  );

  return (
    <div className="space-y-6 p-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Accounts</h1>
          <p className="text-gray-500 mt-1">Manage your customer accounts and their workspaces</p>
        </div>
        <CreateAccountDialog />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {accounts?.map(account => (
          <Link key={account._id} to={`/accounts/${account._id}`}>
            <Card className="hover:shadow-md transition-shadow">
              <CardHeader>
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
                    <Building2 className="w-5 h-5 text-gray-600" />
                  </div>
                  <div>
                    <CardTitle>{account.name}</CardTitle>
                    {account.parentAccount && (
                      <Badge variant="secondary" className="mt-1">
                        Sub Account
                      </Badge>
                    )}
                  </div>
                </div>
              </CardHeader>
              <CardContent>
                <div className="flex items-center text-sm text-gray-500">
                  <Users className="w-4 h-4 mr-2" />
                  <span>
                    {account.teamCount || 0} team members
                  </span>
                </div>
                {account.lastActivity && (
                  <div className="text-sm text-gray-500 mt-2">
                    Last active: {new Date(account.lastActivity).toLocaleDateString()}
                  </div>
                )}
              </CardContent>
            </Card>
          </Link>
        ))}
      </div>

      {accounts?.length === 0 && (
        <div className="text-center py-10">
          <Building2 className="w-12 h-12 mx-auto text-gray-400 mb-4" />
          <p className="text-gray-500">No accounts created yet.</p>
          <Button className="mt-4">
            Create your first account
          </Button>
        </div>
      )}
    </div>
  );
};

export default Home;