import React, { useState, useEffect, useRef } from 'react';
//import { useLocation, useParams, Link } from 'react-router-dom';
import { useLocation, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { 
  ChevronDown, 
  LayoutDashboard, 
  Users, 
  Briefcase, 
  Lightbulb, 
  Building2, 
  Settings, 
  HelpCircle, 
  ChevronLeft,
  Menu,
  X
} from 'lucide-react';
import { cn } from '../lib/utils';
import { accountApi } from '../services/api';
import { useQuery } from 'react-query';
import { Button } from './ui/button';

const LeftSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  
  const accountIdMatch = location.pathname.match(/\/accounts\/([^/]+)/);
  const accountId = accountIdMatch ? accountIdMatch[1] : null;
  const isAccountPage = Boolean(accountIdMatch);

  const [activeOption, setActiveOption] = useState(isAccountPage ? 'all-tasks' : 'accounts');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { data: currentAccount } = useQuery(
    ['currentAccount', accountId],
    () => accountApi.getCurrentAccount(),
    {
      enabled: isAccountPage && Boolean(accountId)
    }
  );

  const { data: accounts } = useQuery('accounts', () => accountApi.getAccounts());

  // Close mobile menu on route change
  useEffect(() => {
    setIsMobileOpen(false);
  }, [location]);

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [accountId, location.pathname]);

  // Handle click outside for dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleAccountSwitch = async (account) => {
    try {
      await accountApi.switchAccount(account._id);
      setIsDropdownOpen(false);
      navigate(`/accounts/${account._id}`);
    } catch (error) {
      console.error('Failed to switch account:', error);
    }
  };

  const handleMenuClick = (optionId) => {
    if (!accountId && isAccountPage) return;
    
    setActiveOption(optionId);
    
    if (!accountId && optionId !== 'accounts') return;
  
    const pathMap = {
      'all-tasks': `/accounts/${accountId}/board/all`,
      'business': `/accounts/${accountId}/board/business`,
      'enablement': `/accounts/${accountId}/board/enablement`,
      'workload': `/accounts/${accountId}/workload/default`,
      'accounts': '/',
      'settings': `/accounts/${accountId}/settings`,
      'help': `/accounts/${accountId}/help`
    };
  
    const targetPath = pathMap[optionId];
    if (targetPath) {
      navigate(targetPath);
    }
  };

  const menuOptions = isAccountPage ? [
    { id: 'all-tasks', label: 'All Tasks', icon: LayoutDashboard },
    { id: 'workload', label: 'Workloads', icon: Users },
    { id: 'business', label: 'Business', icon: Briefcase },
    { id: 'enablement', label: 'Enablement', icon: Lightbulb }
  ] : [
    { id: 'accounts', label: 'Accounts', icon: Building2 },
    { id: 'settings', label: 'Settings', icon: Settings },
    { id: 'help', label: 'Help & Support', icon: HelpCircle }
  ];

  return (
    <>
      {/* Mobile Menu Button */}
      <div className="lg:hidden fixed top-4 left-4 z-50">
        <Button
          variant="ghost"
          size="sm"
          className="w-10 h-10 p-0"
          onClick={() => setIsMobileOpen(!isMobileOpen)}
        >
          {isMobileOpen ? (
            <X className="h-6 w-6" />
          ) : (
            <Menu className="h-6 w-6" />
          )}
        </Button>
      </div>

      {/* Backdrop for mobile */}
      {isMobileOpen && (
        <div 
          className="lg:hidden fixed inset-0 bg-black/50 z-30"
          onClick={() => setIsMobileOpen(false)}
        />
      )}

      {/* Sidebar */}
      <aside
  className={cn(
    "h-[calc(100vh-4rem)] flex-shrink-0 border-r border-gray-200 bg-white",
    "fixed lg:sticky top-16 left-0 z-40",
    "flex flex-col transition-all duration-300 ease-in-out relative", // Added relative for absolute positioning
    isCollapsed ? "w-16" : "w-64",
    isMobileOpen ? "translate-x-0" : "-translate-x-full lg:translate-x-0"
  )}
>
        {/* Collapse button - desktop only */}
        <Button
    variant="ghost"
    size="sm"
    className={cn(
      "hidden lg:flex absolute -right-3 top-1/2 -translate-y-1/2",
      "w-6 h-6 p-0 rounded-full bg-white border shadow-md",
      "hover:bg-white hover:shadow-md" // Remove hover effect but keep shadow
    )}
    onClick={() => setIsCollapsed(!isCollapsed)}
  >
          <ChevronLeft className={cn(
            "h-4 w-4 transition-transform",
            isCollapsed && "rotate-180"
          )} />
        </Button>

        {isAccountPage && (
          <div className={cn(
            "p-4 border-b border-gray-200",
            isCollapsed && "p-2"
          )}>
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => !isCollapsed && setIsDropdownOpen(!isDropdownOpen)}
                className={cn(
                  "w-full text-left bg-white border border-gray-200 rounded-lg transition-colors duration-200 hover:bg-gray-50",
                  isCollapsed ? "p-2" : "px-4 py-2"
                )}
              >
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center flex-shrink-0">
                    <span className="text-sm font-medium text-gray-900">
                      {currentAccount?.name?.charAt(0).toUpperCase()}
                    </span>
                  </div>
                  {!isCollapsed && (
                    <>
                      <span className="truncate text-sm font-medium text-gray-900">
                        {currentAccount?.name}
                      </span>
                      <ChevronDown className="w-4 h-4 ml-auto text-gray-600" />
                    </>
                  )}
                </div>
              </button>

              {isDropdownOpen && !isCollapsed && (
                <div className="absolute w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                  <Link 
                    to="/"
                    className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-900 hover:bg-gray-50 border-b border-gray-200"
                  >
                    <ChevronLeft className="w-4 h-4" />
                    <span>Back to Accounts</span>
                  </Link>
                  {accounts?.map((account) => (
                    <button
                      key={account._id}
                      className="w-full flex items-center space-x-2 px-4 py-2 text-sm text-gray-900 hover:bg-gray-50"
                      onClick={() => handleAccountSwitch(account)}
                    >
                      <div className="w-6 h-6 rounded-full bg-gray-100 flex items-center justify-center">
                        <span className="text-xs font-medium">
                          {account.name?.charAt(0).toUpperCase()}
                        </span>
                      </div>
                      <span className="truncate">{account.name}</span>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        <nav className="flex-1 p-4 space-y-1 overflow-y-auto">
          {menuOptions.map(option => {
            const Icon = option.icon;
            return (
              <button
                key={option.id}
                onClick={() => handleMenuClick(option.id)}
                className={cn(
                  "w-full flex items-center space-x-3 px-3 py-2 text-sm rounded-lg transition-colors duration-200",
                  activeOption === option.id 
                    ? "bg-gray-100 text-gray-900 font-medium" 
                    : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                )}
              >
                <Icon className="w-5 h-5 flex-shrink-0" />
                {!isCollapsed && <span>{option.label}</span>}
              </button>
            );
          })}
        </nav>
      </aside>
    </>
  );
};

export default LeftSidebar;