// Task.js
import React, { memo, useState, useRef, useEffect } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { Card } from '../../components/ui/card';
import { Badge } from '../../components/ui/badge';
import TaskDialog from './TaskDialog';
import { Avatar, AvatarFallback } from '../../components/ui/avatar';
import { Trash2 } from 'lucide-react';
import { taskApi } from '../../services/api';
import { cn } from '../../lib/utils';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../components/ui/alert-dialog';

const getTypeStyles = (type) => {
  const styles = {
    Business: 'bg-blue-100 text-blue-800 border-blue-200',
    Workload: 'bg-green-100 text-green-800 border-green-200',
    Platform: 'bg-purple-100 text-purple-800 border-purple-200',
    Product: 'bg-yellow-100 text-yellow-800 border-yellow-200',
    Enablement: 'bg-pink-100 text-pink-800 border-pink-200'
  };
  return styles[type] || 'bg-gray-100 text-gray-800 border-gray-200';
};

const Task = ({ task, index, onUpdate, onDelete }) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedTitle, setEditedTitle] = useState(task.title);
  const [showTaskDialog, setShowTaskDialog] = useState(false);
  const [editedDescription, setEditedDescription] = useState(task.description || '');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const titleInputRef = useRef(null);
  const descriptionInputRef = useRef(null);

  useEffect(() => {
    if (isEditingTitle && titleInputRef.current) {
      titleInputRef.current.focus();
      titleInputRef.current.select();
    }
  }, [isEditingTitle]);

  useEffect(() => {
    if (isEditingDescription && descriptionInputRef.current) {
      descriptionInputRef.current.focus();
      descriptionInputRef.current.select();
    }
  }, [isEditingDescription]);

  const handleTitleUpdate = async () => {
    if (editedTitle.trim() !== task.title) {
      try {
        const updatedTask = await taskApi.updateTask(task._id, {
          title: editedTitle.trim(),
        });
        onUpdate?.(updatedTask);
      } catch (error) {
        console.error('Failed to update task title:', error);
        setEditedTitle(task.title);
      }
    }
    setIsEditingTitle(false);
  };

  const handleDescriptionUpdate = async () => {
    if (editedDescription.trim() !== task.description) {
      try {
        const updatedTask = await taskApi.updateTask(task._id, {
          description: editedDescription.trim(),
        });
        onUpdate?.(updatedTask);
      } catch (error) {
        console.error('Failed to update task description:', error);
        setEditedDescription(task.description || '');
      }
    }
    setIsEditingDescription(false);
  };

  const handleDelete = async () => {
    try {
      await taskApi.deleteTask(task._id);
      onDelete?.(task._id);
    } catch (error) {
      console.error('Failed to delete task:', error);
    }
  };

  return (
    <Draggable draggableId={String(task._id)} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="mb-3 last:mb-0"
        >
          <Card
            className={cn(
              "bg-white p-3 transition-shadow",
              snapshot.isDragging ? "shadow-lg" : "shadow-sm hover:shadow"
            )}
            onClick={() => setShowTaskDialog(true)} 
          >
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <Badge className={getTypeStyles(task.type)}>
                  {task.type}
                </Badge>
                <button
                  onClick={() => setShowDeleteDialog(true)}
                  className="text-gray-400 hover:text-red-600 transition-colors"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>

              {isEditingTitle ? (
                <input
                  ref={titleInputRef}
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onBlur={handleTitleUpdate}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleTitleUpdate();
                    if (e.key === 'Escape') {
                      setEditedTitle(task.title);
                      setIsEditingTitle(false);
                    }
                  }}
                  className="w-full px-1 py-0.5 text-sm font-medium border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              ) : (
                <h3
                  className="font-medium text-gray-900 cursor-pointer hover:text-blue-600"
                  onDoubleClick={() => setIsEditingTitle(true)}
                >
                  {task.title}
                </h3>
              )}

              {isEditingDescription ? (
                <textarea
                  ref={descriptionInputRef}
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                  onBlur={handleDescriptionUpdate}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.metaKey) handleDescriptionUpdate();
                    if (e.key === 'Escape') {
                      setEditedDescription(task.description || '');
                      setIsEditingDescription(false);
                    }
                  }}
                  className="w-full px-1 py-0.5 text-sm text-gray-600 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[60px]"
                />
              ) : (
                <p
                  className="text-sm text-gray-600 line-clamp-2 cursor-pointer hover:text-blue-600"
                  onDoubleClick={() => setIsEditingDescription(true)}
                >
                  {task.description || 'Add a description...'}
                </p>
              )}

              {task.assignee && (
                <div className="flex items-center pt-2">
                  <Avatar className="h-6 w-6">
                    <AvatarFallback className="bg-gray-200 text-xs">
                      {task.assignee.name?.split(' ').map(n => n[0]).join('') || '??'}
                    </AvatarFallback>
                  </Avatar>
                  <span className="ml-2 text-sm text-gray-600">
                    {task.assignee.name}
                  </span>
                </div>
              )}
            </div>
          </Card>
          
          <TaskDialog
            task={task}
            open={showTaskDialog}
            onOpenChange={setShowTaskDialog}
            onClose={() => setShowTaskDialog(false)}
            onUpdate={onUpdate}
          />

          <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete the task 
                  "{task.title}".
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => setShowDeleteDialog(false)}>
                  Cancel
                </AlertDialogCancel>
                <AlertDialogAction
                  className="bg-red-600 hover:bg-red-700 text-white"
                  onClick={async () => {
                    await handleDelete();
                    setShowDeleteDialog(false);
                  }}
                >
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      )}
    </Draggable>
  );
};

Task.displayName = 'Task';
export default memo(Task);