// src/components/auth/ProtectedRoute.js
import useCheckAuth from '../../hooks/useCheckAuth';

const ProtectedRoute = ({ children, requiredRole }) => {
  const { isLoading, hasAccess } = useCheckAuth(requiredRole);

  if (isLoading) {
    // Render a loading spinner or placeholder
    return <div>Loading...</div>;
  }

  if (!hasAccess) {
    return;
  }

  // User is authorized, render the protected component
  return children;
};

export default ProtectedRoute;