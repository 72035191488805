import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/Buttons.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-oidc-context";


// AWS Cognito Auth Config
const cognitoAuthConfig = {
  authority: process.env.REACT_APP_AWS_AUTHORITY,
  client_id: process.env.REACT_APP_AWS_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_AWS_REDIRECT_URI,
  response_type: process.env.REACT_APP_AWS_RESPONSE_TYPE,
  scope: process.env.REACT_APP_AWS_SCOPE,
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.origin}/silent-renew`
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider {...cognitoAuthConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
