// src/components/AdminMenu.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FileCode, Menu, Settings, LogOut, User, Users } from 'lucide-react'; 
// Removed UserCircle2
import { Button } from '../components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../components/ui/dropdown-menu';
import { useAuth } from "react-oidc-context";
import ProtectedRoute from './auth/ProtectedRoute';


const AdminMenu = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const signOutRedirect = () => {
        auth.removeUser();
        sessionStorage.clear(); // Clear all session storage
        window.location.href = `${process.env.REACT_APP_AWS_COGNITO_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&logout_uri=${encodeURIComponent(process.env.REACT_APP_AWS_LOGOUT_URI)}`;
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="hover:bg-gray-100 rounded-full">
          <Menu className="h-6 w-6" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <DropdownMenuLabel>Admin Tools</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate('/templates')}>
          <FileCode className="mr-2 h-4 w-4" />
          <span>Manage Templates</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate('/settings')}>
          <Settings className="mr-2 h-4 w-4" />
          <span>Settings</span>
        </DropdownMenuItem>
        <ProtectedRoute requiredRole="BelayAdmin">
          <DropdownMenuItem onClick={() => navigate('/profile')}>
            <User className="mr-2 h-4 w-4" />
            <span>Profile</span>
          </DropdownMenuItem>
        </ProtectedRoute>
        <DropdownMenuItem onClick={() => navigate('/admin/groups')}>
          <Users className="mr-2 h-4 w-4" />
          <span>Manage Groups</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={signOutRedirect}>
          <LogOut className="mr-2 h-4 w-4" />
          <span>Sign Out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AdminMenu;