// src/lib/utils.js
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

// Color utilities
export const colorClasses = {
  text: {
    primary: 'text-[rgba(12,11,8,0.75)]',
    secondary: 'text-[rgba(66,59,47,0.75)]',
    emphasis: 'text-[#20201d]',
    brand: 'text-[#1f513f]',
  },
  bg: {
    primary: 'bg-white',
    secondary: 'bg-[#f4f2ed]',
    tertiary: 'bg-[#f9f8f6]',
    brand: 'bg-[#1f513f]',
  },
  border: {
    primary: 'border-[#dcd6cb]',
    secondary: 'border-[#928b80]',
    emphasis: 'border-[#4e4d49]',
  },
  hover: {
    bg: 'hover:bg-[#f9f8f6]',
    bgBrand: 'hover:bg-[#f4f2ed]',
    border: 'hover:border-[#928b80]',
  }
};