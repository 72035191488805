import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { templateApi } from '../services/api';
import { useQueryClient } from 'react-query';
import { Switch } from '../components/ui/switch';
import { Label } from '../components/ui/label';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { Checkbox } from "../components/ui/checkbox";
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from '../components/ui/select';
import { 
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction
} from '../components/ui/alert-dialog';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogDescription,
  DialogFooter 
} from '../components/ui/dialog';
import { Trash2, Plus, GripVertical } from 'lucide-react';
import { Badge } from '../components/ui/badge';

const TemplateCreator = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showTaskDialog, setShowTaskDialog] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [showAddPathDialog, setShowAddPathDialog] = useState(false);

  // Query for existing template
  // eslint-disable-next-line no-unused-vars
  const { data: existingTemplate, isLoading: templateLoading, error } = useQuery(
    ['template', templateId],
    () => templateId ? templateApi.getTemplateById(templateId) : null,
    {
      enabled: !!templateId,
      onError: (error) => {
        console.error('Error fetching template:', error);
      }
    }
  );

  // Initial template state
  const [template, setTemplate] = useState({
    name: '',
    description: '',
    type: '',
    columns: [
      { id: 'recommended', title: 'Recommended', order: 0 },
      { id: 'to-start', title: 'To Start', order: 1 },
      { id: 'on-us', title: 'On Us', order: 2 },
      { id: 'on-customer', title: 'On Customer', order: 3 },
      { id: 'waiting', title: 'Waiting', order: 4 },
      { id: 'blocked', title: 'Blocked', order: 5 },
      { id: 'completed', title: 'Completed', order: 6 }
    ],
    defaultTasks: [],
    settings: {
      allowedTaskTypes: [],
      defaultView: 'kanban',
      autoSync: {
        enabled: false,
        sources: []
      }
    },
    maturityPath: {
      isWorkloadTemplate: false,
      level: 1,
      nextLevelTemplate: null,
      completionCriteria: {
        requiredPercentage: 100,
        requiredTaskStatuses: ['completed']
      }
    }
  });

  useEffect(() => {
    if (existingTemplate) {
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        ...existingTemplate,
        columns: existingTemplate.columns || prevTemplate.columns,
        defaultTasks: existingTemplate.defaultTasks || prevTemplate.defaultTasks,
      }));
    }
    setIsLoading(templateLoading);
  }, [existingTemplate, templateLoading]);
  
  const handleAddColumn = () => {
    const newColumn = {
      id: `column-${Date.now()}`,
      title: 'New Column',
      order: template.columns.length
    };
    setTemplate(prev => ({
      ...prev,
      columns: [...prev.columns, newColumn]
    }));
  };

  const handleUpdateColumn = (index, updates) => {
    setTemplate(prev => ({
      ...prev,
      columns: prev.columns.map((col, i) => 
        i === index ? { ...col, ...updates } : col
      )
    }));
  };

  const handleDeleteColumn = (index) => {
    setTemplate(prev => ({
      ...prev,
      columns: prev.columns.filter((_, i) => i !== index)
    }));
  };

  const handleAddTask = () => {
    setCurrentTask({
      title: '',
      description: '',
      type: template.type,
      status: template.columns[0]?.id,
      priority: 'MEDIUM',
      tags: []
    });
    setShowTaskDialog(true);
  };

  const handleSaveTask = () => {
    if (currentTask._id) {
      // Editing existing task
      setTemplate(prev => ({
        ...prev,
        defaultTasks: prev.defaultTasks.map(task =>
          task._id === currentTask._id ? currentTask : task
        )
      }));
    } else {
      // Adding new task
      setTemplate(prev => ({
        ...prev,
        defaultTasks: [...prev.defaultTasks, {
          ...currentTask,
          _id: `temp-${Date.now()}`  // Temporary ID for UI purposes
        }]
      }));
    }
    setShowTaskDialog(false);
    setCurrentTask(null);
  };

  const handleEditTask = (task) => {
    setCurrentTask(task);
    setShowTaskDialog(true);
  };

  const handleDeleteTask = (taskId) => {
    setTemplate(prev => ({
      ...prev,
      defaultTasks: prev.defaultTasks.filter(task => task._id !== taskId)
    }));
  };

  const CompletionCriteriaConfig = ({ criteria, onChange }) => {
    return (
      <div className="space-y-4">
        <div className="space-y-2">
          <Label>Required Completion Percentage</Label>
          <Input
            type="number"
            min="0"
            max="100"
            value={criteria?.requiredPercentage || 100}
            onChange={(e) => onChange({
              ...criteria,
              requiredPercentage: parseInt(e.target.value, 10)
            })}
          />
        </div>
  
        <div className="space-y-2">
          <Label>Required Task Statuses</Label>
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="status-completed"
                checked={criteria?.requiredTaskStatuses?.includes('completed')}
                onCheckedChange={(checked) => {
                  const currentStatuses = criteria?.requiredTaskStatuses || [];
                  const newStatuses = checked
                    ? [...currentStatuses, 'completed']
                    : currentStatuses.filter(s => s !== 'completed');
                  onChange({
                    ...criteria,
                    requiredTaskStatuses: newStatuses
                  });
                }}
              />
              <Label htmlFor="status-completed">Completed</Label>
            </div>
            <p className="text-sm text-gray-500">
              Tasks must be in the "completed" status to count towards maturity progress
            </p>
          </div>
        </div>
  
        <div className="mt-4 p-4 bg-blue-50 rounded-md">
          <p className="text-sm text-blue-700">
            Current Configuration:
            <br />
            Required Percentage: {criteria?.requiredPercentage || 0}%
            <br />
            Required Statuses: {criteria?.requiredTaskStatuses?.join(', ') || 'None'}
          </p>
        </div>
      </div>
    );
  };

  const MaturityConfig = ({ template, onUpdate }) => {
    const { data: templates } = useQuery('templates', templateApi.getAllTemplates);
    const [showAddPathDialog, setShowAddPathDialog] = useState(false);
    const [pathForm, setPathForm] = useState({
      template: '',
      name: '',
      description: '',
      recommendedFor: ''
    });
  
    return (
      <Card>
        <CardHeader>
          <CardTitle>Workload Maturity Configuration</CardTitle>
          <CardDescription>
            Configure how this workload template progresses to higher maturity levels
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div className="flex items-center space-x-2">
              <Switch
                id="is-workload"
                checked={template.maturityPath?.isWorkloadTemplate}
                onCheckedChange={(checked) => 
                  onUpdate({
                    maturityPath: {
                      ...template.maturityPath,
                      isWorkloadTemplate: checked
                    }
                  })
                }
              />
              <Label htmlFor="is-workload">Enable workload maturity</Label>
            </div>

            {template.maturityPath?.isWorkloadTemplate && (
              <>
                <div className="space-y-2">
                  <Label>Maturity Level</Label>
                  <Select
                    value={template.maturityPath.level}
                    onValueChange={(value) =>
                      onUpdate({
                        maturityPath: {
                          ...template.maturityPath,
                          level: parseInt(value)
                        }
                      })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select level" />
                    </SelectTrigger>
                    <SelectContent>
                      {[1, 2, 3, 4, 5].map((level) => (
                        <SelectItem key={level} value={level}>
                          Level {level}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <Label>Next Level Options</Label>
                    <Button 
                      variant="outline" 
                      size="sm"
                      onClick={() => setShowAddPathDialog(true)}
                    >
                      <Plus className="h-4 w-4 mr-2" />
                      Add Path Option
                    </Button>
                  </div>

                  <div className="space-y-3">
                    {template.maturityPath.nextLevelOptions?.map((path, index) => (
                      <Card key={index} className="bg-gray-50">
                        <CardHeader className="pb-2">
                          <div className="flex items-center justify-between">
                            <CardTitle className="text-base">{path.name}</CardTitle>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => {
                                const newOptions = [...(template.maturityPath.nextLevelOptions || [])];
                                newOptions.splice(index, 1);
                                onUpdate({
                                  maturityPath: {
                                    ...template.maturityPath,
                                    nextLevelOptions: newOptions
                                  }
                                });
                              }}
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                          <CardDescription className="text-sm">
                            {path.description}
                          </CardDescription>
                        </CardHeader>
                        <CardContent>
                          <div className="flex flex-wrap gap-1">
                            {path.recommendedFor?.map((rec, i) => (
                              <Badge key={i} variant="secondary">
                                {rec}
                              </Badge>
                            ))}
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </div>

                <CompletionCriteriaConfig
                  criteria={template.maturityPath.completionCriteria}
                  onChange={(criteria) =>
                    onUpdate({
                      maturityPath: {
                        ...template.maturityPath,
                        completionCriteria: criteria
                      }
                    })
                  }
                />
              </>
            )}

            <Dialog open={showAddPathDialog} onOpenChange={setShowAddPathDialog}>
              <DialogContent className="bg-white">
                <DialogHeader>
                  <DialogTitle>Add Next Level Path</DialogTitle>
                  <DialogDescription>
                    Configure a new path option for the next maturity level
                  </DialogDescription>
                </DialogHeader>
                
                <div className="space-y-4">
                  <div className="space-y-2">
                    <Label>Template</Label>
                    <Select
                      value={pathForm.template}
                      onValueChange={(value) => setPathForm(prev => ({ ...prev, template: value }))}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select template" />
                      </SelectTrigger>
                      <SelectContent>
                        {templates
                          ?.filter(t => 
                            t.maturityPath?.level === (template.maturityPath.level + 1) &&
                            !template.maturityPath.nextLevelOptions?.some(opt => 
                              opt.template === t._id
                            )
                          )
                          .map((t) => (
                            <SelectItem key={t._id} value={t._id}>
                              {t.name}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="space-y-2">
                    <Label>Path Name</Label>
                    <Input
                      value={pathForm.name}
                      onChange={(e) => setPathForm(prev => ({ ...prev, name: e.target.value }))}
                      placeholder="e.g., Enterprise Path"
                    />
                  </div>

                  <div className="space-y-2">
                    <Label>Description</Label>
                    <Input
                      value={pathForm.description}
                      onChange={(e) => setPathForm(prev => ({ ...prev, description: e.target.value }))}
                      placeholder="When to choose this path..."
                    />
                  </div>

                  <div className="space-y-2">
                    <Label>Recommended For (comma-separated)</Label>
                    <Input
                      value={pathForm.recommendedFor}
                      onChange={(e) => setPathForm(prev => ({ ...prev, recommendedFor: e.target.value }))}
                      placeholder="Enterprise, Large Teams, etc."
                    />
                  </div>
                </div>

                <DialogFooter>
                  <Button
                    onClick={() => {
                      onUpdate({
                        maturityPath: {
                          ...template.maturityPath,
                          nextLevelOptions: [
                            ...(template.maturityPath.nextLevelOptions || []),
                            {
                              template: pathForm.template,
                              name: pathForm.name,
                              description: pathForm.description,
                              recommendedFor: pathForm.recommendedFor.split(',').map(s => s.trim()).filter(Boolean)
                            }
                          ]
                        }
                      });
                      setShowAddPathDialog(false);
                      setPathForm({ template: '', name: '', description: '', recommendedFor: '' });
                    }}
                    disabled={!pathForm.template || !pathForm.name || !pathForm.description}
                  >
                    Add Path
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        </CardContent>
      </Card>
    );
  };
  
  const isEditMode = !!templateId;

  // Update handleSave to handle both create and update:
  const handleSave = async () => {
  try {
    const templateData = {
      ...template,
      defaultTasks: template.defaultTasks.map(task => ({
        title: task.title,
        description: task.description,
        type: task.type || template.type,
        status: task.status,
        priority: task.priority || 'MEDIUM',
        tags: task.tags || [],
        metadata: {
          estimatedDuration: task.metadata?.estimatedDuration,
          prerequisites: task.metadata?.prerequisites || [],
          nextSteps: task.metadata?.nextSteps || []
        }
      }))
    };

    console.log("Template data being sent:", JSON.stringify(templateData, null, 2));

    if (isEditMode) {
      await templateApi.updateTemplate(templateId, templateData);
    } else {
      await templateApi.createTemplate(templateData);
    }

    queryClient.invalidateQueries('templates');
    navigate('/templates');
  } catch (error) {
    console.error('Error saving template:', error);
  }
};

  if (isLoading || templateLoading) {
    return <div className="flex items-center justify-center h-48">Loading template...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Create Template</h1>
        <div className="space-x-2">
          <Button variant="outline" onClick={() => navigate('/templates')}>
            Cancel
          </Button>
          <Button onClick={() => setShowConfirmDialog(true)}>
            Save Template
          </Button>
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Template Details</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="text-sm font-medium">Name</label>
              <Input
                value={template.name}
                onChange={(e) => setTemplate(prev => ({
                  ...prev,
                  name: e.target.value
                }))}
                placeholder="Template name"
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium">Type</label>
              <Select
  className="bg-white"
  value={template.type}
  onValueChange={(value) => setTemplate(prev => ({
    ...prev,
    type: value
  }))}
>
  <SelectTrigger>
    <SelectValue placeholder="Select type" />
  </SelectTrigger>
  <SelectContent className="bg-white">
    <SelectItem value="Workload">Workload</SelectItem>
    <SelectItem value="Business">Business</SelectItem>
    <SelectItem value="Enablement">Enablement</SelectItem>
  </SelectContent>
</Select>
            </div>
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Description</label>
            <Input
              value={template.description}
              onChange={(e) => setTemplate(prev => ({
                ...prev,
                description: e.target.value
              }))}
              placeholder="Template description"
            />
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>Columns</CardTitle>
            <Button size="sm" onClick={handleAddColumn}>
              <Plus className="w-4 h-4 mr-2" />
              Add Column
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            {template.columns.map((column, index) => (
              <div key={column.id} className="flex items-center space-x-2 p-2 bg-gray-50 rounded">
                <GripVertical className="w-4 h-4 text-gray-400" />
                <Input
                  value={column.title}
                  onChange={(e) => handleUpdateColumn(index, { title: e.target.value })}
                  className="flex-1"
                />
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleDeleteColumn(index)}
                >
                  <Trash2 className="w-4 h-4 text-gray-400" />
                </Button>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>Default Tasks</CardTitle>
            <Button size="sm" onClick={handleAddTask}>
              <Plus className="w-4 h-4 mr-2" />
              Add Task
            </Button>
          </div>
          <p className="text-sm text-gray-500">
            These tasks will be automatically created when a board uses this template
          </p>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {template.defaultTasks.map((task) => (
              <div 
                key={task._id} 
                className="flex items-start justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                onClick={() => handleEditTask(task)}
              >
                <div className="space-y-2">
                  <div className="flex items-center space-x-2">
                    <h3 className="font-medium">{task.title}</h3>
                    <Badge variant="secondary">{task.status}</Badge>
                    <Badge className="bg-blue-100 text-blue-800">{task.type}</Badge>
                  </div>
                  <p className="text-sm text-gray-600">{task.description}</p>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteTask(task._id);
                  }}
                >
                  <Trash2 className="w-4 h-4 text-gray-400" />
                </Button>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
      <MaturityConfig 
  template={template} 
  onUpdate={(updates) => setTemplate(prev => ({...prev, ...updates}))}
/>
      <Dialog open={showTaskDialog} onOpenChange={setShowTaskDialog}>
      <DialogContent className="sm:max-w-[425px] bg-white border shadow-md">
      <DialogHeader>
            <DialogTitle>
              {currentTask?._id ? 'Edit Task' : 'Add Task'}
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <label className="text-sm font-medium">Title</label>
              <Input
                value={currentTask?.title || ''}
                onChange={(e) => setCurrentTask(prev => ({
                  ...prev,
                  title: e.target.value
                }))}
                placeholder="Task title"
              />
            </div>
            <div className="space-y-2">
  <label className="text-sm font-medium">Description</label>
  <Input
    value={currentTask?.description || ''}
    onChange={(e) => setCurrentTask(prev => ({
      ...prev,
      description: e.target.value
    }))}
    placeholder="Task description"
    className="min-h-[80px]"  // Use height instead of rows
  />
</div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">Status</label>
                <Select
                  value={currentTask?.status || ''}
                  onValueChange={(value) => setCurrentTask(prev => ({
                    ...prev,
                    status: value
                  }))}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select status" />
                  </SelectTrigger>
                  <SelectContent className="bg-white border shadow-md">
                    {template.columns.map(column => (
                      <SelectItem key={column.id} value={column.id}>
                        {column.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Priority</label>
                <Select
                  value={currentTask?.priority || 'MEDIUM'}
                  onValueChange={(value) => setCurrentTask(prev => ({
                    ...prev,
                    priority: value
                  }))}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select priority" />
                  </SelectTrigger>
                  <SelectContent className="bg-white border shadow-md">
                    <SelectItem value="LOW">Low</SelectItem>
                    <SelectItem value="MEDIUM">Medium</SelectItem>
                    <SelectItem value="HIGH">High</SelectItem>
                    <SelectItem value="URGENT">Urgent</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowTaskDialog(false)}>
              Cancel
            </Button>
            <Button onClick={handleSaveTask}>
              {currentTask?._id ? 'Update Task' : 'Add Task'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Save Template</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to save this template? Once created, some properties cannot be modified.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleSave}>
              Save Template
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default TemplateCreator;