import React from 'react';
import { Calendar, Link2, AlertCircle, Clock, Paperclip } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../components/ui/dialog';
import { Button } from '../../components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { Label } from '../../components/ui/label';
import { Badge } from '../../components/ui/badge';
import { Avatar, AvatarFallback } from '../../components/ui/avatar';

const TaskDialog = ({ task, open, onOpenChange, onUpdate, onClose }) => {
  const [editedTask, setEditedTask] = React.useState(task);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setEditedTask(task);
  }, [task]);

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await onUpdate(editedTask);
      onClose();
    } catch (error) {
      console.error('Failed to update task:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (field) => (e) => {
    setEditedTask(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  const handleSelectChange = (field) => (value) => {
    setEditedTask(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
<DialogContent className="max-w-3xl bg-white h-[90vh] max-h-screen flex flex-col overflow-hidden">
<DialogHeader>
  <div className="flex items-center justify-between pr-8"> {/* Add pr-8 for right padding */}
    <DialogTitle className="text-xl">Task Details</DialogTitle>
    <Badge 
      variant="outline" 
      className={
        editedTask?.priority === 'HIGH' ? 'bg-red-100 text-red-800 border-red-200' :
        editedTask?.priority === 'MEDIUM' ? 'bg-yellow-100 text-yellow-800 border-yellow-200' :
        'bg-blue-100 text-blue-800 border-blue-200'
      }
    >
      {editedTask?.priority}
    </Badge>
  </div>
</DialogHeader>
        <div className="flex-1 overflow-y-auto py-4 px-6">
        <div className="grid gap-6 py-4">
          {/* Title */}
          <div className="space-y-2">
            <Label htmlFor="title">Title</Label>
            <Input
              id="title"
              value={editedTask?.title || ''}
              onChange={handleInputChange('title')}
              className="w-full"
            />
          </div>
        </div>
          {/* Type and Priority Row */}
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>Type</Label>
              <Select
                value={editedTask?.type}
                onValueChange={handleSelectChange('type')}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Business">Business</SelectItem>
                  <SelectItem value="Workload">Workload</SelectItem>
                  <SelectItem value="Platform">Platform</SelectItem>
                  <SelectItem value="Product">Product</SelectItem>
                  <SelectItem value="Enablement">Enablement</SelectItem>
                  <SelectItem value="Technical">Technical</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2 pb-2">
              <Label>Priority</Label>
              <Select
                value={editedTask?.priority}
                onValueChange={handleSelectChange('priority')}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select priority" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="LOW">Low</SelectItem>
                  <SelectItem value="MEDIUM">Medium</SelectItem>
                  <SelectItem value="HIGH">High</SelectItem>
                  <SelectItem value="URGENT">Urgent</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* Description */}
          <div className="space-y-2 pb-5">
            <Label htmlFor="description">Description</Label>
            <Textarea
              id="description"
              value={editedTask?.description || ''}
              onChange={handleInputChange('description')}
              rows={4}
            />
          </div>

          {/* Due Date and Assignee Row */}
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label className="flex items-center gap-2">
                <Calendar className="w-4 h-4" />
                Due Date
              </Label>
              <Input
                type="date"
                value={editedTask?.dueDate ? new Date(editedTask.dueDate).toISOString().split('T')[0] : ''}
                onChange={handleInputChange('dueDate')}
              />
            </div>

            <div className="space-y-2">
              <Label>Assignee</Label>
              <div className="flex items-center space-x-2">
                <Avatar className="h-8 w-8">
                  <AvatarFallback className="bg-gray-200 text-xs">
                    {editedTask?.assignee?.name?.split(' ').map(n => n[0]).join('') || '??'}
                  </AvatarFallback>
                </Avatar>
                <Select
                  value={editedTask?.assignee?._id}
                  onValueChange={handleSelectChange('assignee')}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Assign to..." />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="user1">John Doe</SelectItem>
                    <SelectItem value="user2">Jane Smith</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>

          {/* Additional Metadata */}
          <div className="space-y-4">
            {/* Estimation */}
            <div className="flex items-center gap-2">
              <Clock className="w-4 h-4 text-gray-500" />
              <Label>Estimated Time</Label>
              <Input
                type="number"
                placeholder="Hours"
                className="w-24"
                value={editedTask?.metadata?.estimatedHours || ''}
                onChange={(e) => setEditedTask(prev => ({
                  ...prev,
                  metadata: {
                    ...prev.metadata,
                    estimatedHours: e.target.value
                  }
                }))}
              />
            </div>

            {/* External Links */}
            <div className="space-y-2">
              <Label className="flex items-center gap-2">
                <Link2 className="w-4 h-4" />
                External Links
              </Label>
              <Input
                placeholder="Add URL"
                value={editedTask?.metadata?.externalLink || ''}
                onChange={(e) => setEditedTask(prev => ({
                  ...prev,
                  metadata: {
                    ...prev.metadata,
                    externalLink: e.target.value
                  }
                }))}
              />
            </div>

            {/* Attachments Placeholder */}
            <div className="space-y-2">
              <Label className="flex items-center gap-2">
                <Paperclip className="w-4 h-4" />
                Attachments
              </Label>
              <div className="text-sm text-gray-500 italic">
                Attachment functionality coming soon...
              </div>
            </div>

            {/* Notes */}
            <div className="space-y-2">
              <Label className="flex items-center gap-2">
                <AlertCircle className="w-4 h-4" />
                Notes
              </Label>
              <Textarea
                placeholder="Add any additional notes..."
                value={editedTask?.metadata?.notes || ''}
                onChange={(e) => setEditedTask(prev => ({
                  ...prev,
                  metadata: {
                    ...prev.metadata,
                    notes: e.target.value
                  }
                }))}
                rows={3}
              />
            </div>
          </div>
        </div>

        <DialogFooter className="bg-white px-6 py-4 border-t">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave} disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save Changes'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TaskDialog;