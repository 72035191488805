// UserSettings.js
import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { Switch } from '../../components/ui/switch';
import { useToast } from '../../components/ui/use-toast';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../../components/ui/alert-dialog';

const UserSettings = ({ user, onUpdateSettings }) => {
  const [notificationSettings, setNotificationSettings] = useState({
    email: user?.preferences?.notifications?.email ?? true,
    slack: user?.preferences?.notifications?.slack ?? true
  });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { toast } = useToast();

  const handleNotificationChange = async (type) => {
    try {
      const newSettings = {
        ...notificationSettings,
        [type]: !notificationSettings[type]
      };
      
      setNotificationSettings(newSettings);
      await onUpdateSettings({
        preferences: {
          notifications: newSettings
        }
      });

      toast({
        title: "Settings updated",
        description: "Your notification preferences have been saved.",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update settings. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Notification Preferences</CardTitle>
          <CardDescription>
            Configure how you would like to receive notifications
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex items-center justify-between space-x-2">
            <Label htmlFor="email-notifications" className="flex flex-col space-y-1">
              <span>Email Notifications</span>
              <span className="text-sm text-gray-500">
                Receive updates via email
              </span>
            </Label>
            <Switch
              id="email-notifications"
              checked={notificationSettings.email}
              onCheckedChange={() => handleNotificationChange('email')}
            />
          </div>
          <div className="flex items-center justify-between space-x-2">
            <Label htmlFor="slack-notifications" className="flex flex-col space-y-1">
              <span>Slack Notifications</span>
              <span className="text-sm text-gray-500">
                Receive updates via Slack
              </span>
            </Label>
            <Switch
              id="slack-notifications"
              checked={notificationSettings.slack}
              onCheckedChange={() => handleNotificationChange('slack')}
            />
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Account Settings</CardTitle>
          <CardDescription>
            Manage your account preferences and security settings
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid gap-4">
            <div className="space-y-2">
              <Label htmlFor="default-board">Default Board</Label>
              <Input
                id="default-board"
                defaultValue={user?.preferences?.defaultBoard}
                disabled
              />
            </div>
          </div>
          <div className="pt-4">
            <Button
              variant="destructive"
              onClick={() => setShowDeleteDialog(true)}
            >
              Delete Account
            </Button>
          </div>
        </CardContent>
      </Card>

      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              account and remove all data from our servers.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction variant="destructive">
              Delete Account
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default UserSettings;
