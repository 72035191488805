// src/hooks/useCheckAuth.js
import { useAuth } from 'react-oidc-context';
import { useEffect, useState } from 'react';

const useCheckAuth = (requiredRole) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = auth.user;

        if (!user) {
          setHasAccess(false);
          setIsLoading(false);
          return;
        }

        // Get user's groups from Cognito
        const groups =  user.profile['cognito:groups'];

        if (requiredRole && groups.includes(requiredRole)) {
          setHasAccess(true);
        } else {
          setHasAccess(false);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error checking auth:', error);
        setHasAccess(false);
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [auth, requiredRole]);

  return { isLoading, hasAccess };
};

export default useCheckAuth;