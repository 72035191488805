import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import BoardPage from './pages/BoardPage';
import Home from './pages/Home';
import AccountPage from './pages/AccountPage';
import LeftSidebar from './components/LeftSidebar';
import AdminBanner from './components/AdminBanner';
import TemplatesPage from './pages/TemplatesPage';
import TemplateCreator from './pages/TemplateCreator';
import UserPages from './pages/UserPages';
import UserMenu from './components/user/UserMenu';
import UserProfile from './components/UserProfile';
import SilentRenew from './pages/SilentRenew';
import { useAuth } from "react-oidc-context";
import AdminMenu from './components/AdminMenu.js';
import { cn } from "./lib/utils";
import { Toaster } from "./components/ui/toaster"
import useCheckAuth from './hooks/useCheckAuth';
import './App.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
});

// Component for the main layout with sidebar
const MainLayout = ({ children }) => {
  const auth = useAuth();
  // Get current user data from your auth context/state management
  //unused const user = {}; // Replace with actual user data source

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow-rei fixed w-full z-50 pt-3 h-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-full">
            <div className="flex items-center">
              <Link to="/">
                <img 
                  src={`${process.env.PUBLIC_URL}/logo.png`}
                  alt="Logo"
                  className="h-8 w-auto"
                />
              </Link>
            </div>
            
            <div className="flex items-center space-x-4">
              <AdminMenu />
              <UserMenu user={{
                name: auth.isAuthenticated ? auth.user?.profile.name?.toString() : null,
                email: auth.isAuthenticated ? auth.user?.profile.email : null
              }} />
            </div>
          </div>
        </div>
      </nav>

      <div className="flex pt-16 relative">
        <LeftSidebar />
        <main className={cn(
          "flex-1 transition-all duration-300",
          "min-h-[calc(100vh-4rem)]",
          "lg:ml-0"
        )}>
          <div className="p-6">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

function App() {
  const auth = useAuth();
  const { hasAccess: isBelayUser } = useCheckAuth('BelayUsers');
  const { hasAccess: isBelayAdmin } = useCheckAuth('BelayAdmin');

  const signOutRedirect = () => {
    auth.removeUser();
    sessionStorage.clear(); // Clear all session storage
    window.location.href = `https://belay.auth.us-east-1.amazoncognito.com/logout?client_id=5r6d2aap07bsm45l93qpu0hjv7&logout_uri=${process.env.REACT_APP_AWS_LOGOUT_URI}`;
}

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Encountering error... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated || !isBelayUser) {
    return (
      <QueryClientProvider client={queryClient}>
      <Router>
        <div className="min-h-screen bg-gray-50">
          {/* Header/Navigation with REI styling */}
          <nav className="bg-white shadow-rei fixed w-full z-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex items-center">
                  <Link to="/">
                    <img 
                      src={`${process.env.PUBLIC_URL}/logo.png`}
                      alt="Logo"
                      className="h-8 w-auto"
                    />
                  </Link>
                </div>
                
                {/* User Menu with REI styling */}
                <div className="flex items-center">
                {  (auth.isAuthenticated && !isBelayUser) ? (
                  <button 
                  onClick={signOutRedirect}
                  className="text-gray-600 hover:text-gray-900"
                >
                  Sign out
                </button>
                 ) : (
                <button onClick={() => auth.signinRedirect()}>Sign in</button> 
                )
                }
                </div>
              </div>
              { (auth.isAuthenticated && !isBelayUser) ? (
                <div className="flex justify-between h-16">
                <div className="flex items-center">
                  Your account access to this application has not been authorized or is suspended. Please contact your administrator.
                </div>  
                </div>
              ): null }
            </div>
          </nav>

          {/* Main layout with REI styling */}
          <div className="pt-16 flex">
          </div>
        </div>
      </Router>
    </QueryClientProvider>
  );

  }

  if (auth.isAuthenticated && isBelayUser) {
    //do this better  
    if (!localStorage.getItem('token') && auth.user?.access_token) {
      localStorage.setItem('token', auth.user.access_token);
    }

    return (
    <QueryClientProvider client={queryClient}>
      <Router>
      {isBelayAdmin && <AdminBanner />}
      <div className={`${isBelayAdmin ? 'mt-10' : ''}`}>
        <div className="min-h-screen bg-gray-50">
          <Routes>
            
            {/* All other routes - with sidebar */}
            <Route path="*" element={
              <MainLayout>
                <Routes>
                  {/* Home and Template routes */}
                  <Route path="/" element={<Home />} />
                  <Route path="/templates" element={<TemplatesPage />} />
                  <Route path="/templates/new" element={<TemplateCreator />} />
                  <Route path="/templates/edit/:templateId" element={<TemplateCreator />} />
                  
                  {/* User routes */}
                  <Route path="/user/*" element={<UserPages />} />
                  
                  {/* Account dashboard */}
                  <Route path="/accounts/:accountId" element={<AccountPage />} />
                  
                  {/* Board routes - now flat */}
                  <Route path="/accounts/:accountId/board/all" element={<BoardPage />} />
                  <Route path="/accounts/:accountId/board/business" element={<BoardPage />} />
                  <Route path="/accounts/:accountId/board/enablement" element={<BoardPage />} />
                  <Route path="/accounts/:accountId/workload/:workloadId" element={<BoardPage />} />
                  
                  {/* Settings and Help pages */}
                  <Route 
                    path="/accounts/:accountId/settings" 
                    element={
                      <div className="p-4">
                        <h1 className="text-2xl font-bold mb-4">Settings</h1>
                        <p className="text-gray-500">Settings page content coming soon...</p>
                      </div>
                    } 
                  />
                  <Route 
                    path="/accounts/:accountId/help" 
                    element={
                      <div className="p-4">
                        <h1 className="text-2xl font-bold mb-4">Help & Support</h1>
                        <p className="text-gray-500">Help and support content coming soon...</p>
                      </div>
                    } 
                  />
                  {/* Other routes */}
                  <Route path="/profile" element={<UserProfile />} />
                  {/* Catch all */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                  <Route path="/silent-renew" element={<SilentRenew />} />
                </Routes>
              </MainLayout>
            } />
          </Routes>
          <Toaster />
        </div>
        </div>
      </Router>
    </QueryClientProvider>
  );
  }
}

export default App;