// src/pages/SilentRenew.js
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

const SilentRenew = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.signinSilentCallback();
  }, [auth]);

  return <div>Loading...</div>;
};

export default SilentRenew;